<template>
    <div>
        <MallheaderVue
                :titlename="'电商专区'"
                :customstyle="{ background: '#FFFFFF', position: 'relative'}"
                :colorType="false"
        >
            <template slot="headbody">
                <div class="bgmall">

                    <div class="maincontent">
                        <div class="hometop">
                            <div class="itemlist">
                                <div class="item" @click="toBannerUrl(item.url)"  v-for="(item, index) in bannerList" :key="index">
                                    <img class="itemimg" :src="item.images_url" />
                                    <div class="itemtitle van-multi-ellipsis--l2">
                                        {{ item.name }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </MallheaderVue>
    </div>
</template>

<script>
    import MallheaderVue from "../../components/mallheader.vue";
    import { getBanner } from "@/request/api";
    export default {
        components: {
            MallheaderVue,
        },
        data() {
            return {
                refreshing: false,
                loading: false,
                finished: false,
                order_count: 0,
                wallet_count: 0,
                recommendlist: [],
                storeinfo: {
                    store_id: 0,
                },
                userinfo: {
                    name: "",
                    id: "",
                },
                walletInfo: [],
                address: "",
                short_address: "",
                bannerList:[],
            };
        },
        mounted() {

        },
        activated() {
            getBanner({ type: "2" }).then((res) => {
                this.bannerList = res.data;
            });
        },
        methods: {
            toBannerUrl(url){
                window.open(url,'_blank')
            },
        },
    };
</script>

<style scoped lang="scss">
    .bgmall {
        background: #ebeef2;
        min-height: 100vh;
        padding-bottom: 10px;
        .maincontent {
            .hometop {
                padding-top: 50px;
                /*background: url("../../assets/images/mall/home/topbg.png");*/
                /*background-size: 100% 100%;*/
                height:420px;
                .itemlist {
                    background: #ebeef2;
                    margin-top: 14px;
                    padding: 0 24px 16px 24px;
                    display: flex;
                    justify-content: space-between;
                    flex-flow: wrap;
                    .item {
                        margin-bottom: 24px;
                        width: 210px;
                        height: auto;
                        border-radius: 24px;
                        opacity: 1;
                        background: rgba(255, 255, 255, 1);
                        .itemimg {
                            width: 210px;
                            height: 210px;
                            border-radius: 24px 24px 0 0;
                            opacity: 1;
                            background: rgba(255, 114, 32, 1);
                            /*object-fit: cover;*/
                        }
                        .itemtitle {
                            margin-top: 16px;
                            padding: 0 16px;
                            color: rgba(41, 33, 29, 1);
                            font-size: 30px;
                            font-weight: 600;
                            font-family: "PingFang SC";
                            text-align: left;
                            line-height: 36px;
                        }
                        .bottom {
                            margin-top: 16px;
                            padding: 0 16px;
                            display: flex;
                            justify-content: space-between;
                            .price {
                                color: rgba(255, 114, 32, 1);
                                font-weight: 500;
                                font-family: "DIN";
                                text-align: left;
                                line-height: 40px;
                                font-size: 28px;
                            }
                            .todetail {
                                color: rgba(156, 152, 150, 1);
                                font-size: 24px;
                                font-weight: 400;
                                font-family: "PingFang SC";
                                text-align: left;
                                line-height: 32px;
                            }
                        }
                    }
                }
            }
        }
    }
</style>
